



























import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseIconCard from "@/components/shared/BaseIconCard.vue";
import { DashboardRouteNames } from "@/shared/enums/RouteNames/dashboardRouteNames.enum";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { ApiGetGuestUserDashboardDto } from "@/api/generated/Api";
import { api } from "@/api/api";

export default defineComponent({
  name: "GuestUserDashboard",
  components: { BaseLayout, BaseIconCard },
  setup() {
    const dashboardData = ref<ApiGetGuestUserDashboardDto>();

    onMounted(async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        dashboardData.value = (await api.guestside.getDashboardSelfAsync1()).data;
      });
    });

    return {
      DashboardRouteNames,
      dashboardData,
    };
  },
});
